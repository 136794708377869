import React from "react"
import styled from "styled-components"
import Title from "../Title"
import MapMarkerIcon from "../Icons/MapMarkerIcon"
import PhoneIcon from "../Icons/PhoneIcon"
import EnvelopeIcon from "../Icons/EnvelopeIcon"

import { theme } from "../../utils/styled_config"

const ContactDetails = () => {
  return (
    <>
      <Title size={3} lg={2} mb={4}>
        Contact Us
      </Title>
      <ContactRow>
        <MapMarkerIcon />
        6189 Gabaldon, Poblacion
        <br />
        Makati City 1210 Metro Manila
      </ContactRow>
      <ContactRow>
        <PhoneIcon />
        <span>02 8741 3074</span>
      </ContactRow>
      <ContactRow>
        <EnvelopeIcon />
        ea@tajarahospitality.com
      </ContactRow>
    </>
  )
}

const ContactRow = styled.div`
  display: flex;
  margin-bottom: 1rem;

  .icon {
    color: ${theme.color.brand};
    font-size: 24px;
    margin-right: 12px;
    margin-top: 2px;
  }
`

export default ContactDetails
