import styled from "styled-components"
import { space } from "styled-system"

const Paragraph = styled.p`
  line-height: 1.8;
  margin-bottom: 0;
  ${space};

  & + p {
    margin-top: 0.75rem;
  }
`
export default Paragraph
