import React from "react"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import ContactUsHero from "../components/contact_us/ContactUsHero"
import Section from "../components/Section"
import Container from "../components/Container"
import Row from "../components/Row"
import Col from "../components/Col"
import ContactForm from "../components/contact_us/ContactForm"
import ContactDetails from "../components/contact_us/ContactDetails"

const ContactUs = () => {
  return (
    <>
      <Seo title="Contact Us" />
      <BlurredSection>
        <ContactUsHero />
        <Section>
          <Container>
            <Row>
              <Col lg={7}>
                <ContactForm />
              </Col>
              <Col lg={5}>
                <ContactDetails />
              </Col>
            </Row>
          </Container>
        </Section>
      </BlurredSection>
    </>
  )
}

export default ContactUs
