import React from "react"
import Icon from "../Icon"

const ArrowRightIcon = () => {
  return (
    <Icon viewBox="0 0 25 25">
      <path d="M15.5 5.5L14.09 6.91L18.67 11.5H2.5V13.5H18.67L14.08 18.09L15.5 19.5L22.5 12.5L15.5 5.5Z" />
    </Icon>
  )
}

export default ArrowRightIcon
