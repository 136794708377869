import React from "react"
import Icon from "../Icon"

const MapMarkerIcon = () => {
  return (
    <Icon viewBox="0 0 24 24">
      <path d="M12.5 2C8.63 2 5.5 5.13 5.5 9C5.5 10.74 6 12.37 6.91 13.84C7.86 15.38 9.11 16.7 10.07 18.24C10.54 18.99 10.88 19.69 11.24 20.5C11.5 21.05 11.71 22 12.5 22C13.29 22 13.5 21.05 13.75 20.5C14.12 19.69 14.45 18.99 14.92 18.24C15.88 16.71 17.13 15.39 18.08 13.84C19 12.37 19.5 10.74 19.5 9C19.5 5.13 16.37 2 12.5 2ZM12.5 11.75C11.12 11.75 10 10.63 10 9.25C10 7.87 11.12 6.75 12.5 6.75C13.88 6.75 15 7.87 15 9.25C15 10.63 13.88 11.75 12.5 11.75Z" />
    </Icon>
  )
}

export default MapMarkerIcon
