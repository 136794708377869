import React from "react"
import styled from "styled-components"

const LinedText = ({ title, width, height, close }) => {
  return (
    <Line height={height} width={width} close={close}>
      <svg
        viewBox="0 0 221 16"
        height="100%"
        width="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.144 0.0494655C104.159 0.203788 99.0564 0.482596 96.8046 0.668873C93.0953 0.975447 92.6719 1.13847 92.3043 2.40159C91.9113 3.75095 91.6686 3.80131 84.7358 3.9757C80.7963 4.07397 72.2398 4.27938 65.7211 4.43114C59.2024 4.5829 48.0396 4.95576 40.9148 5.25971C33.79 5.56366 24.302 6.12103 19.8305 6.49943C15.359 6.87722 10.8527 7.33111 9.81653 7.50794C8.27597 7.7708 7.97374 8.02856 8.15785 8.92205C8.35808 9.89372 7.91906 10.0501 4.19604 10.3347C1.89315 10.5108 0.00495725 10.8753 2.05147e-05 11.1441C-0.00491622 11.4129 0.881778 11.8474 1.97042 12.1088C3.05949 12.3708 5.4763 12.4661 7.3416 12.3206C9.20734 12.175 14.9813 11.9118 20.1731 11.7354C25.3649 11.5591 36.3302 11.1947 44.5403 10.9257C53.7757 10.6232 74.7751 10.7566 99.6093 11.2759C121.687 11.7381 146.452 12.5694 154.642 13.1239C162.833 13.6783 175.402 14.6319 182.574 15.2434C193.154 16.146 195.842 16.2122 196.828 15.5954C197.496 15.1779 199.329 14.6483 200.902 14.4197C202.475 14.1911 204.754 13.7318 205.966 13.399C207.179 13.0657 210.702 12.6285 213.794 12.4269C216.887 12.2254 219.469 11.9598 219.532 11.837C219.596 11.7142 220.016 10.8313 220.465 9.87528C220.99 8.75855 221.135 7.91956 220.871 7.53016C220.644 7.19611 218.317 6.5679 215.7 6.13365C213.082 5.69939 209.265 5.21582 207.217 5.05945C205.168 4.9037 195.601 4.39663 185.956 3.9342C176.312 3.47116 164.38 2.69194 159.442 2.20266C154.504 1.71338 142.572 0.965583 132.926 0.540765C123.281 0.115948 112.129 -0.105473 108.144 0.0494655Z"
          fill="#C33B32"
        />
      </svg>
      <b>{title}</b>
    </Line>
  )
}

const Line = styled.span`
  position: relative;
  display: inline-flex;

  & > b {
    position: relative;
    z-index: 2;
    font-weight: 400;
  }

  & > svg {
    position: absolute;
    bottom: ${props => (props.close ? "4px" : "-16px")};
    left: 0;
    height: ${props => props.height || "13px"};
    width: ${props => props.width || "100%"};
  }
`

export default LinedText
