import React from "react"
import styled from "styled-components"
import ArrowRightIcon from "./Icons/ArrowRightIcon"
import { Link } from "gatsby"

const ArrowCta = ({
  type,
  onClick,
  to,
  title,
  arrow,
  align,
  disabled,
  target,
  transform,
}) => {
  const arrowType = () => {
    if (arrow === "circle") {
      return (
        <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25 15L23.59 16.41L28.17 21H12V23H28.17L23.58 27.59L25 29L32 22L25 15Z"
            fill="#C33B32"
          />
          <rect
            x="1"
            y="1"
            width="42"
            height="42"
            rx="21"
            stroke="#C33B32"
            strokeWidth="2"
          />
        </svg>
      )
    } else {
      return <ArrowRightIcon as="span" />
    }
  }

  return type === "button" || type === "submit" ? (
    <ArrowButtonStyled
      onClick={onClick}
      arrow={arrow}
      align={align}
      type={type}
      disabled={disabled}
      transform={transform}
    >
      <span>{title}</span>
      {arrowType()}
    </ArrowButtonStyled>
  ) : (
    <ArrowLinkStyled
      to={to}
      arrow={arrow}
      align={align}
      as={target && "a"}
      href={target && target}
      target={target && "_blank"}
      rel={target && "noopener noreferrer"}
    >
      <span>{title}</span>
      {arrowType()}
    </ArrowLinkStyled>
  )
}

const ArrowLinkStyled = styled(Link)`
  font-size: 18px;
  min-height: 40px;
  display: inline-flex;
  align-items: ${props => (props.align ? props.align : "flex-end")};
  letter-spacing: 0.04em;
  text-transform: ${props =>
    props.transform === "reset" ? "none" : "uppercase"};
  color: #fff;
  font-weight: 300;
  transition: all 0.2s ease-in-out;

  svg {
    font-size: 24px;
    margin-left: ${props => (props.arrow === "circle" ? "16px" : "8px")};
    transition: all 0.2s ease-in-out;
    color: #c33b32;
    height: ${props => (props.arrow === "circle" ? "44px" : "1em")};
  }

  &:hover {
    color: #fff;
    svg {
      transform: translateX(4px);
    }
  }
`

const ArrowButtonStyled = styled.button`
  font-size: 18px;
  min-height: 40px;
  display: inline-flex;
  align-items: ${props => (props.align ? props.align : "flex-end")};
  letter-spacing: 0.04em;
  text-transform: ${props =>
    props.transform === "reset" ? "none" : "uppercase"};
  color: #fff;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;

  svg {
    font-size: 24px;
    margin-left: ${props => (props.arrow === "circle" ? "16px" : "8px")};
    transition: all 0.2s ease-in-out;
    color: #c33b32;
    height: ${props => (props.arrow === "circle" ? "44px" : "1em")};
  }

  &:hover {
    color: #fff;
    svg {
      transform: translateX(4px);
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

export default ArrowCta
