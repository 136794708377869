import React, { useState, useCallback, useRef } from "react"
import styled, { css } from "styled-components"
import { useForm, Controller } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha";
import { BREAKPOINTS, theme } from "../../utils/styled_config"
import InputMask from "react-input-mask"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import Row from "../Row"
import { navigate } from "gatsby-link"
import Col from "../Col"
import Paragraph from "../Paragraph"
import ArrowCta from "../ArrowCta"

const schema = yup.object().shape({
  name: yup.string().required("This is required."),
  email: yup
    .string()
    .required("This is required.")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,13}$/i,
      "Please enter a valid email."
    ),
  mobile: yup.string().test("len", "Invalid mobile number.", val => {
    if (val) return !val.includes("_")
  }),
  company: yup.string().required("This is required."),
  message: yup.string().required("This is required."),
})
const ContactForm = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const captchaRef = useRef(null);
  const [isVerified, setIsVerified] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const onSubmit = async data => {
    setIsSubmitting(true)
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    try {
      const response = await fetch(`${process.env.GATSBY_CONTACT_US_API_URL}`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": process.env.GATSBY_CONTACT_US_API_KEY,
        },
        body: JSON.stringify(data),
      })
      const json = await response.json()
      if (response.ok && isVerified) {
        navigate("/thank-you")
        setIsSubmitting(false)
        captchaRef.current.reset();
      } else {
        // show error message
        setError(json.message)
        setIsSubmitting(false)
      }
    } catch (err) {
      // show error
      setError(err.message)
      setIsSubmitting(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {error && <p className="text-danger">{error}</p>}
      <FieldGroup errors={errors.name}>
        <Label htmlFor="name">
          Full Name
          <sup>*</sup>
        </Label>
        <FieldContainer>
          <Field {...register("name")} placeholder="What is your name?" />
        </FieldContainer>
        {errors.name && <FieldError>{errors.name.message}</FieldError>}
      </FieldGroup>
      <FieldGroup errors={errors.email}>
        <Label htmlFor="email">
          Email Address
          <sup>*</sup>
        </Label>
        <FieldContainer>
          <Field
            {...register("email", {
              required: true,
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            })}
            placeholder="What is your email?"
          />
        </FieldContainer>
        {errors.email && <FieldError>{errors.email.message}</FieldError>}
      </FieldGroup>
      <FieldGroup errors={errors.mobile}>
        <Label htmlFor="mobile">
          Mobile No.
          <sup>*</sup>
        </Label>
        <div>
          <Controller
            name="mobile"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputMask
                mask=" 999 999 9999"
                value={value}
                alwaysShowMask={true}
                onChange={onChange}
              >
                {inputProps => <Field {...inputProps} type="tel" />}
              </InputMask>
            )}
          />
        </div>
        {errors.mobile && <FieldError>{errors.mobile.message}</FieldError>}
      </FieldGroup>

      <FieldGroup errors={errors.company}>
        <Label htmlFor="company">
          Company
          <sup>*</sup>
        </Label>
        <FieldContainer>
          <Field
            {...register("company")}
            placeholder="What is the name of your company?"
          />
        </FieldContainer>
        {errors.company && <FieldError>{errors.company.message}</FieldError>}
      </FieldGroup>
      <FieldGroup errors={errors.message}>
        <Label htmlFor="">
          Message
          <sup>*</sup>
        </Label>
        <FieldContainer>
          <Textarea
            as="textarea"
            {...register("message", {
              required: true,
            })}
            placeholder="Type your message here..."
          />
        </FieldContainer>
        {errors.message && <FieldError>{errors.message.message}</FieldError>}
      </FieldGroup>

      <Row justify="center">
        <Col col={10} lg={7}>
          <Terms>
            <Paragraph>
              By clicking ‘Submit,’ you are agreeing to our
              <br />
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                TERMS AND CONDITIONS
              </a>
              &nbsp;and&nbsp;
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                PRIVACY POLICY
              </a>
            </Paragraph>
            <Captcha
              sitekey={process.env.GATSBY_CONTACT_US_API_SITEKEY}
              onChange={useCallback(() => setIsVerified(true))}
              ref={captchaRef}
              className="captcha"
              style={{
                'marginBottom': "40px",
              }}
            />
            <ArrowCta
              arrow="circle"
              type="submit"
              align="center"
              disabled={isSubmitting}
              title={isSubmitting ? "Sending..." : "Submit Inquiry"}
            ></ArrowCta>
          </Terms>
        </Col>
      </Row>
    </Form>
  )
}

const Form = styled.form`
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    padding-right: 60px;
  }
`

const Captcha = styled(ReCAPTCHA)`
  > div {
    div {
      margin: 0 auto;
    }
  }
`

const Field = styled.input`
  width: 100%;
  height: 44px;
  padding: 12px 8px;
  border: 1px solid transparent;
  border-bottom-color: #757575;
  background: none;
  color: #fff;
  transition: ${theme.transition};

  &:focus {
    border-bottom-color: white;
    outline: none;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    height: 48px;
  }
`

const FieldGroup = styled.div`
  margin-bottom: 1.5rem;

  input,
  ${Field} {
    ${p =>
      p.errors &&
      css`
        border-bottom-color: ${theme.color.danger};
      `}
  }
`

const Label = styled.label`
  text-transform: uppercase;
  font-size: 12px;

  sup {
    color: ${theme.color.danger};
  }
`

const FieldContainer = styled.div`
  position: relative;
`

const FieldError = styled.div`
  color: ${theme.color.danger};
  font-size: 12px;
`

const Textarea = styled(Field)`
  height: 100px;
  resize: none;
`

const Terms = styled.div`
  text-align: center;

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }

  ${Paragraph} {
    margin-bottom: 2rem;
  }
`

export default ContactForm
