import React from "react"
import Icon from "../Icon"

const EnvelopeIcon = () => {
  return (
    <Icon viewBox="0 0 25 24">
      <path d="M20.5 4H4.5C3.4 4 2.51 4.9 2.51 6L2.5 18C2.5 19.1 3.4 20 4.5 20H20.5C21.6 20 22.5 19.1 22.5 18V6C22.5 4.9 21.6 4 20.5 4ZM20.5 8L12.5 13L4.5 8V6L12.5 11L20.5 6V8Z" />
    </Icon>
  )
}

export default EnvelopeIcon
